<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      <el-breadcrumb-item>管理员列表</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 添加按钮 -->
      <el-button type="primary"
                 class="addbtn"
                 @click="showAddDialog">添加管理员</el-button>
      <!-- 视图列表区 -->
      <el-table :data="listDate"
                stripe
                style="width: 100%">
        <el-table-column type="index"
                         label="#"></el-table-column>
        <el-table-column prop="username"
                         label="帐号"></el-table-column>
        <el-table-column prop="nickname"
                         label="使用者昵称"></el-table-column>
        <el-table-column prop="email"
                         label="邮箱"></el-table-column>
        <el-table-column prop="role_name"
                         label="角色"></el-table-column>
        <el-table-column label="是否启用"
                         prop="status">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status"
                       :active-value="1"
                       :inactive-value="0"
                       @change="userStateChange(scope.row)"></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作"
                         width="200">
          <template slot-scope="scope">
            <el-button type="primary"
                       icon="el-icon-edit"
                       size="mini"
                       @click="showEditDialog(scope.row.id)">编辑</el-button>
            <el-button type="warning"
                       icon="el-icon-delete"
                       size="mini"
                       v-if="scope.row.status===0"
                       @click="deleteById(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <!-- 添加表单 -->
    <el-dialog title="添加管理员"
               :visible.sync="addDialogVisible"
               :close-on-click-modal="false"
               width="50%"
               @close="addDialogClose">
      <!-- 表单区域 -->
      <el-form :model="addForm"
               :rules="addFormRules"
               ref="addFormRef"
               label-position="top">
        <el-form-item label="管理员帐号："
                      prop="username">
          <el-input v-model="addForm.username"
                    label-width="10px"></el-input>
        </el-form-item>
        <el-form-item label="初始密码："
                      prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="使用者昵称："
                      prop="nickname">
          <el-input v-model="addForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="邮箱："
                      prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="权限分配："
                      prop="role_id">
          <el-select v-model="addForm.role_id"
                     placeholder="请选择权限">
            <el-option v-for="role_v in roleList"
                       :key="role_v.id"
                       :label="role_v.role_name"
                       :value="role_v.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="addUser">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑表单 -->
    <el-dialog title="添加管理员"
               :visible.sync="editDialogVisible"
               :close-on-click-modal="false"
               width="50%">
      <!-- 修改管理员 表单内容 -->
      <el-form :model="editForm"
               :rules="editFormRules"
               ref="editFormRef"
               label-position="top">
        <el-form-item label="管理员帐号：">
          <el-input v-model="editForm.username"
                    disabled></el-input>
        </el-form-item>
        <el-form-item label="重置密码："
                      prop="password">
          <el-input v-model="editForm.password"></el-input>
        </el-form-item>
        <el-form-item label="使用者昵称："
                      prop="nickname">
          <el-input v-model="editForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="邮箱："
                      prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="管理权限："
                      prop="role_id"
                      v-if="editForm.role_id !== 1">
          <el-select v-model="editForm.role_id"
                     placeholder="请选择权限">
            <el-option v-for="role_v in roleList"
                       :key="role_v.id"
                       :label="role_v.role_name"
                       :value="role_v.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary"
                   @click="upUserInfo">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data () {
    return {
      listDate: [],
      addDialogVisible: false,
      editDialogVisible: false,

      // 添加用户表单
      addForm: {
        username: '',
        nickname: '',
        password: '123456',
        email: '',
        role_id: ''
      },
      // 添加管理员，验证规则
      addFormRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 5, max: 10, message: '长度在 5 到 10 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 5, max: 16, message: '长度在 5 到 10 个字符', trigger: 'blur' }
        ],
        nickname: [],
        role_id: [
          { required: true, message: '请选择相关权限', trigger: 'change' }
        ]
      },

      // 修改用户表单
      editForm: {
        username: '',
        password: '',
        nickname: '',
        email: '',
        role_id: ''
      },
      // 添加管理员，验证规则
      editFormRules: {
        nickname: [],
        email: [],
        role_id: [
          { required: true, message: '请选择相关权限', trigger: 'blur' }
        ]
      },
      // 权限列表
      roleList: []
    }
  },

  methods: {
    // 获取全部管理员数据
    async getUserList () {
      const { data: res } = await this.$http.get('admins')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.listDate = res.data.data
    },

    // 改变状态
    async userStateChange (userinfo) {
      const { data: res } = await this.$http.put(`admins/${userinfo.id}?status=${userinfo.status}`)
      if (res.code !== 200) {
        userinfo.status = !userinfo.status
        return this.$message.error('更新状态失败！')
      }
      this.$message.success('更新状态成功！')
      // 刷新列表
      this.getUserList()
    },

    // 删除管理员信息
    async deleteById (id) {
      const confirmResult = await this.$confirm('您确定要删除这个管理员吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }
      ).catch(err => err)

      // 如果用户取消了删除
      if (confirmResult !== 'confirm') {
        return this.$message.info('已取消删除！')
      }
      const { data: res } = await this.$http.delete('admins/' + id, {
        headers: { Authorization: localStorage.getItem('token') }
      })
      if (res.code !== 200) {
        return this.$message.error('管理员删除失败，请重试...')
      }
      this.$message.success('成功删除管理员')
      this.getUserList()
    },

    // 添加用户列表按钮
    showAddDialog () {
      this.getRoleList()
      this.addDialogVisible = true
    },

    // 获取管理权限模块
    async getRoleList () {
      const { data: res } = await this.$http.get('roles')
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.roleList = res.data
    },

    // 关闭 Dialog，清除历史数据
    addDialogClose () {
      this.$refs.addFormRef.resetFields()
    },

    // 发起添加请求
    addUser () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) return
        // 如果正确发起添加请求
        const { data: res } = await this.$http.post('admins', this.addForm)
        if (res.code !== 200) {
          this.$message.error('添加用户失败，请重试。')
        }
        this.$message.success('成功添加管理员！')
        // 隐藏对话框
        this.addDialogVisible = false
        this.getUserList()
      })
    },

    // 展示修改对话框
    async showEditDialog (id) {
      const { data: res } = await this.$http.get('admins/' + id)
      if (res.code !== 200) {
        return this.$message.error('网络延时，请刷新页面重试...')
      }
      this.editForm = res.data
      this.getRoleList()
      this.editDialogVisible = true
    },

    // 上传修改的管理员资料
    upUserInfo () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) return
        const { data: res } = await this.$http.put('admins/' + this.editForm.id, {
          password: this.editForm.password,
          email: this.editForm.email,
          nickname: this.editForm.nickname,
          role_id: this.editForm.role_id
        })
        if (res.code !== 200) {
          return this.$message.error('修改管理员信息失败，请重试。')
        }
        this.$message.success('修改成功！')
        // 隐藏对话框
        this.editDialogVisible = false
        this.getUserList()
      })
    }
  },

  created () {
    this.getUserList()
  }
}
</script>

<style>
</style>
